import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';

const MissionPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "seniors.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
  `);
  const { fluid } = data.placeholderImage.childImageSharp;
  const style = {
    maxWidth: fluid.presentationWidth,
    margin: '0 auto', // Used to center the image
  };
  return (
    <Layout>
      <SEO
        description="Our mission goals to provide the best quality care possible."
        title="Mission"
      />
      <h1>Our Mission</h1>
      <p>
        The mission of our facilities is to provide the highest level and quality of care possible
        to obtain the highest quality of life, with dignity and respect. Partnership with the client
        and family members is important to solve any issues that may arise and to meet individual
        needs.
      </p>
      <Img style={style} alt="elderly care" fluid={fluid} />
      <h2>Goals</h2>
      <ul>
        <li>
          Enhance the quality of life for residents affording them the freedom and choice of
          independence
        </li>
        <li>
          Encourage residents to maintain their highest functional abilities in a supervised
          environment and to assist them in their daily living and activities
        </li>
        <li>
          Offer an environment with services designed to meet their individual and group needs
        </li>
        <li>
          Provide nutritious meals and snacks with emphasis on maintaining and improving their
          overall health
        </li>
      </ul>
    </Layout>
  );
};

export default MissionPage;
